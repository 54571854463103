export default function SvgRiseLogoFigure(props) {
  return (
    <svg fill="none" viewBox="0 0 164 237" {...props}>
      <g clip-path="url(#clip0_87_28)">
        <path
          fill="url(#paint0_linear_87_28)"
          fill-rule="evenodd"
          d="m0 0 96.06 96.2 22.26 22.29-22.26 22.29L0 236.98V100.89h30.46v61l43.34-43.4L0 44.59V0Zm119.48 236.98H164l-59.74-59.83L82 199.44l37.48 37.54Z"
          clip-rule="evenodd"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_87_28"
          x1="15.23"
          x2="15.23"
          y1="236.98"
          y2="100.89"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FF00A6" />
          <stop offset="1" stop-color="#7B01B4" />
        </linearGradient>
        <clipPath id="clip0_87_28">
          <path fill="#fff" d="M0 0h164v237H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
