import SvgRiseLogoFigure from "./svgs/SvgRiseLogoFigure.js";
import SvgRiseLogoText from "./svgs/SvgRiseLogoText.js";
import SvgMaterialSymbolsBookOutline from "./svgs/SvgMaterialSymbolsBookOutline.js";
import SvgIcBaselineDiscord from "./svgs/SvgIcBaselineDiscord.js";
import SvgMdiTwitter from "./svgs/SvgMdiTwitter.js";
import SvgMdiGithub from "./svgs/SvgMdiGithub.js";

import "./index.css";

export default function AppV2() {
  return (
    <main>
      <div className="logo-container">
        <SvgRiseLogoFigure className="logo-figure" />
        <SvgRiseLogoText className="logo-text" />
      </div>
      <nav className="social-container">
        <a
          className="social-link"
          href="https://discord.gg/4yWVabz63y"
          target="_blank"
          rel="noreferrer"
        >
          <SvgIcBaselineDiscord className="social-icon" />
        </a>
        <a
          className="social-link"
          href="https://twitter.com/rise_chain"
          target="_blank"
          rel="noreferrer"
        >
          <SvgMdiTwitter className="social-icon" />
        </a>
        <a
          className="social-link"
          href="https://github.com/risechain"
          target="_blank"
          rel="noreferrer"
        >
          <SvgMdiGithub className="social-icon" />
        </a>
      </nav>
      <div className="hero-container">
        <h1 className="hero-title">{"Parallelized EVM Layer\xa02"}</h1>
        <div className="hero-subtitle">{"Ethereum’s Broadband Moment"}</div>
      </div>
      <div className="footer-container">
        <span className="footer-text">{"Under Construction"}</span>
      </div>
      <div className="whitepaper-container">
        <a
          className="whitepaper-link"
          href="https://github.com/risechain/whitepaper/blob/main/RISE%20White%20Paper%20-%20Draft%20v0.5.pdf"
          target="_blank"
          rel="noreferrer"
        >
          <SvgMaterialSymbolsBookOutline className="whitepaper-icon" />
          <span>{"Whitepaper"}</span>
        </a>
      </div>
    </main>
  );
}
